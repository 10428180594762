import React from "react";
import Footer from "./Footer";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsAlarm } from "react-icons/bs";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";

export const Contacto = () => {
  return (
    <>
    <div className="text-center bg-secondary text-white p-2" >
          <div className="row">
          <div className="col-3 col-md-4"></div>
            <div className="col-4 col-md-2">
            <a
              className="text-decoration-none"
              href="https://www.facebook.com/groups/centromedicosanmartin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                id="face"
                className="d-flex justify-content-center align-items-center text-white"
              >
                <FaFacebookSquare size={24} />
                <h6 className="mt-2 mx-2 ">Facebook </h6>
              </div>
            </a>
          </div>
          
          <div className="col-4 col-md-2">
            <a
              className="text-decoration-none"
              href="https://instagram.com/centro.sanmartin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                id="face"
                className="d-flex justify-content-center align-items-center text-white"
              >
                <FaInstagramSquare size={24} />
                <h6 className="mt-2 mx-2 ">Instagram </h6>
              </div>
            </a>
          </div>
          <div className="col-3 col-md-4"></div>
        </div>
        </div>
      <div className="container text-center">
        <img src="imagenes/brand.jpg" alt="foto" className="img-fluid mt-2" />

        

        <div
          className="card border-primary m-4 p-4 "
          style={{ borderRadius: "15px" }}
        >
          <h4 className="text-center bg-info mb-4">
            {" "}
            <b>TURNOS Y CONSULTAS</b>
          </h4>

          <div className="row justify-content-center items-center ">
            <div className="col-8  col-md-4">
              <div className="row">
                <div class="col text-h3 ">
                  <div>
                    <h5>
                      <b>CONSULTORIOS</b>
                    </h5>
                  </div>
                  <div className="text-p2">
                    <h6>Secretaria : P. Andrea Moscheni Vera</h6>

                    <div className="d-flex align-items-center">
                      <BsFillTelephoneFill />{" "}
                      <h6 className="mt-2 mx-2">Telefono: 433 3059 </h6>
                    </div>

                    <a
                      href="https://api.whatsapp.com/send?phone=+542646184400&text=Turno web"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div id="whatsapp" className="d-flex align-items-center">
                        <img
                          src="./imagenes/whatsapp.png"
                          title="Escríbenos por Whastapp"
                          width={"20px"}
                          alt="Escríbenos por Whastapp"
                        />
                        <h6 className="mt-2 mx-2">Whatsapp: 2646184400 </h6>
                      </div>
                    </a>

                    <p className="mx-4 d-flex align-items-star">
                      {" "}
                      (solo mensajes)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8  col-md-4">
              <div className="row">
                <div class="col text-h3 ">
                  <div>
                    <h5>
                      <b>ECOGRAFIAS</b>
                    </h5>
                  </div>
                  <h6>Secretaria : P. Andrea Moscheni Vera</h6>
                  <div class="text-p2">
                    <a
                      href="https://api.whatsapp.com/send?phone=+542646741359&text=Turno web"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div id="whatsapp" className="d-flex align-items-center">
                        <img
                          src="./imagenes/whatsapp.png"
                          title="Escríbenos por Whastapp"
                          width={"20px"}
                          alt="Escríbenos por Whastapp"
                        />
                        <h6 className="mt-2 mx-2">Whatsapp: 2646741359 </h6>
                      </div>
                    </a>
                    <p className="mx-4 d-flex align-items-star">
                      {" "}
                      (solo mensajes)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8  col-md-3">
              <div className="row">
                <div class="col text-h3 ">
                  <div>
                    <h5>
                      <b>LABORATORIO</b>
                    </h5>
                  </div>
                  <h6>Secretaria : A. Carolina Rico</h6>
                  <div class="text-p2">
                    <a
                      href="https://api.whatsapp.com/send?phone=+542645777559&text=Turno web"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div id="whatsapp" className="d-flex align-items-center">
                        <img
                          src="./imagenes/whatsapp.png"
                          title="Escríbenos por Whastapp"
                          width={"20px"}
                          alt="Escríbenos por Whastapp"
                        />
                        <h6 className="mt-2 mx-2">Whatsapp: 2645777559 </h6>
                      </div>
                    </a>
                    <p className="mx-4 d-flex align-items-star">
                      {" "}
                      (solo mensajes)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="card border-primary m-4 p-4 "
          style={{ borderRadius: "15px" }}
        >
          <h4 className="text-center bg-info mb-4">
            <BsAlarm /> <b>HORARIO DE ATENCIÓN</b>
          </h4>

          <div className="row justify-content-center items-center ">
            <div className="col-8  col-md-4">
              <div className="row">
                <div class="col text-h3 ">
                  <div className="mb-2">
                    <b>CONSULTORIOS</b>
                  </div>
                  <div class="text-p2">
                    <p>de LUNES a VIERNES</p>
                    <p>de 8:00 hs. a 12:00 hs.</p>
                    <p>y de 16:30 hs. a 20:00 hs.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8  col-md-4">
              <div className="row">
                <div class="col text-h3 ">
                  <div className="mb-2">
                    <b>LABORATORIO</b>
                  </div>
                  <div class="text-p2">
                    <p>de LUNES a VIERNES</p>
                    <p>de 7:30 hs. a 12:00 hs.</p>
                    <p>y de 16:30 hs. a 20:00 hs.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-center mt-4 p-4 bg-primary text-white">
          COMO LLEGAR
        </h3>

        <iframe
          src="https://maps.google.com/?ll=-31.5303433,-68.5939558&z=17&t=m&output=embed"
          width="100%"
          height="450"
          title="map"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <Footer></Footer>
    </>
  );
};
