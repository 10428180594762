import React from 'react'
import Footer from './Footer'

import { BsFillTelephoneFill } from "react-icons/bs";

export const Pediatra = () => {
  return (
    <>
      <div className='container mb-4 h-100' style={{ minHeight: '67vh' }}>
        <div className="row">

          <div className="col-md-4 text-center">

            <img src="imagenes/pediatra.jpg" alt="lab" width={300} />


          </div>
          <div className="col-md-8">
            <h1 className='m-4'>Consultorio Pediatrico</h1>
            <h4 className='m-4 text-primary'>Atendido por la Dra. Lilia Palacio</h4>


            <div className=' mb-4'>
              <h6>Atencion en pediatria</h6>
              <p>
                La Pediatría es una rama de la medicina que se ocupa de la salud del ser
                humano desde la concepción hasta el final de la adolescencia.
                Nuevas corrientes redefinen el rol social de la medicina pediátrica señalando un
                nuevo posicionamiento destinado a aclarar, debatir, expresar y declarar lo que sucede
                en la sociedad.

              </p>

              <ul>
                <li>Consultorio pediatrico gral</li>
                
              </ul>
            </div>
          </div>


        </div>

        <div className="row">
        <div className='col-md-4'></div>
          <div className="col-md-4">
            <div className="card mb-4">

              <h5 className="mt-4 mx-2">HORARIO DE ATENCIÓN</h5>
              <p className=" mx-2">DIAS: MARTES Y JUEVES</p>
              
              <p className="mx-2">de tarde, CON TURNO</p>

            </div>


          </div>
          <div className="col d-flex">
            <div className="card mb-4">
            <div class="col text-h3 p-4 ">
                  <div ><h5><b>TURNOS Y CONSULTAS</b></h5></div>
                  <div className="text-p2">

                    <div className='d-flex align-items-center'>

                      <BsFillTelephoneFill /> <h6 className='mt-2 mx-2'>Telefono: 433 3059 </h6>
                    </div>


                    <a href="https://api.whatsapp.com/send?phone=+542646184400&text=Turno web clinica medica" target="_blank" rel="noopener noreferrer" >

                      <div id="whatsapp" className='d-flex align-items-center'>
                        <img src="./imagenes/whatsapp.png" title="Escríbenos por Whastapp" width={'20px'} alt="Escríbenos por Whastapp" />
                        <h6 className='mt-2 mx-2'>Whatsapp: 2646184400 </h6>
                      </div>
                    </a>


                  </div>
                  <p className="mx-4"> (solo mensajes)</p>

                </div>
            </div>
          </div>

        </div>


 
      </div>
<Footer></Footer>
     

    </>

  )
}


