import React from "react";
import { useState } from "react";
import { useAuth } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import { Alert } from "./Alert";
import { Link } from "react-router-dom";

export function Login() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { login, loginWithGoogle, resetPassword } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState();

  const handleChange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await login(user.email, user.password);
      navigate("/admin");
    } catch (error) {
      //console.log(error.code);
      if (error.code === "auth/user-not-found") {
        setError("Correo invaido");
      }
      if (error.code === "auth/too-many-requests") {
        setError("Probaste demasiadas veces");
      }

      if (error.code === "auth/wrong-password") {
        setError("Contraseña incorrecta");
      }

      //setError(error.message);
    }
  };

  const handleGoogleSignin = async () => {
    try {
      await loginWithGoogle();
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleResetPassword = async () => {
    if (!user.email) return setError("Please enter you email");
    try {
      await resetPassword(user.email);
      setError("Enviamos un link para resetear la contraseña");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="bg-dark">
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          width: "100vw",
          height: "100vh",
          paddingTop: "50px",
        }}
      >
        <div
          className="card"
          style={{
            width: "400px",
            boxShadow: "rgb(0 0 0 / 20%) 1px 5px 10px",
            paddingTop: "50px",
            paddingBottom: "20px",
            paddingLeft: "10px",
            paddingRight: "10px",
            borderRadius: "8px",
            backgroundColor: "rgb(145,195,143)",
            background:
              "linear-gradient(180deg, rgba(145,195,143,1) 15%, rgba(156,203,213,1) 85%)",
          }}
        >
          <h4 className="text-center mb-2">Bienvenidos al sistema</h4>
          {error && <Alert message={error}></Alert>}

          <form onSubmit={handleSubmit} className="p-4 ">
            <div className="form-floating mb-3">
              <input
                onChange={handleChange}
                required
                type="email"
                name="email"
                className="form-control"
                placeholder="correo"
                id="email"
              />
              <label htmlFor="email">Correo electrónico</label>
            </div>
            <div className="form-floating mb-4">
              <input
                type="password"
                name="password"
                className="form-control"
                id="floatingPassword"
                placeholder="password"
                onChange={handleChange}
                required
              />
              <label htmlFor="floatingPassword">Contraseña</label>
            </div>

            <div>
              <div className="d-grid gap-2 mt-3">
                <button className="btn btn-primary">Ingresar</button>
              </div>

              <p className="text-center mt-2">
                <a
                  href="#!"
                  className="inline-block align-baseline "
                  onClick={handleResetPassword}
                >
                  Olvidaste tu contraseña ?
                </a>
              </p>
            </div>
          </form>

          <div className=" d-flex justify-content-around">
            {/*<Link to='/register'> 
                    <p className="mb-4 ">No estas registrado ?</p>
            </Link> */}
          </div>

          <div className="d-grid gap-2 mx-4">
            <button
              onClick={handleGoogleSignin}
              className="btn btn-warning shadow-md rounded border-2 py-2 px-4"
            >
              Entrar con GMail
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
