import React from 'react'
import Footer from './Footer'

export const Staff = () => {
  return (
    <>
      <div className='container text-center'>
        <h3 className='m-4 '>Nuestros Profesionales</h3>
       {/* <img src='/imagenes/portada2.png' className='img-fluid' alt="" />*/}
        
       
          <img src="imagenes/profesionales.jpg" alt=""  className='img-fluid mb-4'/>
       
      {/*  <div className="m-4 p-4 card border-primary" style={{ borderRadius: '15px', boxShadow: '10px 10px 0px 0px rgba(71,146,214,0.35)' }}>

          <div className="row">
            <div className="col-md-6">
              <ul>
                <li>
                  <h5>CLINICA MEDICA</h5>
                  <p><b>Dr. Ricardo Sanz </b></p>
                </li>
                <li>
                  <h5>PEDIATRIA</h5>
                  <p><b>Dra. Lilia Palacio</b></p>
                </li>
                <li>
                  <h5>ODONTOLOGIA</h5>
                  <p><b>Dra. Lucia Ibañez</b></p>
                </li>
                <li>
                  <h5>DIABETOLOGIA</h5>
                  <p><b>Dr. Mauricio Castro</b></p>
                </li>
                <li>
                  <h5>ECOGRAFIA</h5>
                  <p><b>Dr. Sergio de la Vega</b></p>
                </li>

              </ul>

            </div>

            <div className="col-md-6">
              <ul>

                <li>
                  <h5>CARDIOLOGIA</h5>
                  <p><b>Dr. Raul Trigo</b></p>
                </li>

                <li>
                  <h5>NUTRICION</h5>
                  <p><b>Lic. Carolina Quiroga</b></p>
                </li>
                <li>
                  <h5>PSICOLOGIA</h5>
                  <p><b>Lic. Isabel Galleguillo</b></p>
                </li>
                <li>
                  <h5>LABORATORIO</h5>
                  <p><b>Bioq. Maria Lorena Herrera</b></p>
                  <p><b>Bioq. Sandra V. Gomez</b></p>
                  <p><b>Bioq. Adriana Di Salvo</b></p>
                </li>

              </ul>

            </div>

          </div>
        </div>

  */}
  
  <div>
          
       
       </div>

      </div>
      <Footer></Footer>

    </>

  )
}
