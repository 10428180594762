import React, { useEffect, useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsAlarm } from "react-icons/bs";
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import './Home.css';

import Carrusel from './Carrusel';

import Cards from "./Cards";
import Footer from "./Footer";


export function Home() {

  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const instalarApp = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('El usuario aceptó la solicitud de instalación');
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const triggerHeight = 100;

      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - triggerHeight
      ) {
        // Verificar si la notificación ya se mostró anteriormente
        const notificationShown = sessionStorage.getItem('notificationShown');
        if (!notificationShown) {
          // Mostrar la notificación
          setShowInstallPrompt(true);
          sessionStorage.setItem('notificationShown', 'true');
        }
      } else {
        setShowInstallPrompt(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const showNotification = () => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          new Notification('¿Quieres instalar la aplicación?', {
            body: 'Haz click aquí para instalar la aplicación.',
          }).onclick = instalarApp;
        }
      });
    }
  };

  useEffect(() => {
    if (showInstallPrompt) {
      showNotification();
    }
  }, [showInstallPrompt]);


  return (
    <>
    <div className="text-center bg-secondary text-white p-2" >
          <div className="row">
          <div className="col-3 col-md-4"></div>
            <div className="col-4 col-md-2">
            <a
              className="text-decoration-none"
              href="https://www.facebook.com/groups/centromedicosanmartin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                id="face"
                className="d-flex justify-content-center align-items-center text-white"
              >
                <FaFacebookSquare size={24} />
                <h6 className="mt-2 mx-2 ">Facebook </h6>
              </div>
            </a>
          </div>
          
          
          
          <div className="col-4 col-md-2">
            <a
              className="text-decoration-none"
              href="https://instagram.com/centro.sanmartin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                id="face"
                className="d-flex justify-content-center align-items-center text-white"
              >
                <FaInstagramSquare size={24} />
                <h6 className="mt-2 mx-2 ">Instagram </h6>
              </div>
            </a>
          </div>
          <div className="col-3 col-md-4"></div>
        </div>
        </div>
      <div className="carrusel">
        <Carrusel />
      </div>


      <div className="p-2">

        <div className="servicios container m-2">

          <div className="card">

            <div className="overflow">
              <img src="imagenes/IMG-20230924.jpg" className="card-img-top" alt="imagen" />
            </div>

            <div className="card-body">

              <h3 className="card-title mt-4 mb-4 text-center">CONSULTORIOS SAN MARTIN</h3>
              <p className="card-text">
                Nuestra misión es cuidar de tu salud y bienestar de manera integral.
                <br /> Somos un equipo de profesionales altamente calificados y comprometidos con la excelencia médica.
                <br /> Nuestro enfoque es proporcionar atención médica de calidad, personalizada y compasiva a cada uno de nuestros pacientes.
                <br /> Contamos con instalaciones modernas y equipamiento de última generación para ofrecer una amplia gama de servicios médicos, desde consultas generales hasta especialidades médicas avanzadas.
                <br /> Nuestro compromiso con la innovación y la actualización constante nos permite brindar los tratamientos y diagnósticos más avanzados.

                <br /> Tu salud es nuestra prioridad. Estamos aquí para cuidarte a ti y a tu familia en cada etapa de la vida. Confía en nosotros para recibir atención médica de confianza y de la más alta calidad.
                <br /> ¡Esperamos poder servirte y acompañarte en tu camino hacia una vida más saludable!
              </p>
            </div>
          </div>

          <br /><br />
          <h4 className="m-2 ">¿NECESITAS ATENCIÓN MÉDICA ?</h4>

          <h5 className="m-2">Conoce todas nuestras especialidades y nuestros servicios</h5>
          <Cards />
          
            <h4 className="bg-success text-white text-center p-4">SE RECIBEN OBRAS SOCIALES</h4>
          
          
        </div>
      </div>
      <div className=" mt-4 mb-4 p-4" >
        <h4 className="text-center mb-4 bg-info"> <b>TURNOS Y CONSULTAS</b></h4>
        <div className="row justify-content-center items-center mx-auto rounded-pill">


          <div className="col-8  col-md-3">

            <div className="row" >
              <div className="col text-h3 ">
                <div ><h5><b>CONSULTORIOS</b></h5></div>
                <div className="text-p2">

                  <div className='d-flex align-items-center'>

                    <BsFillTelephoneFill /> <h6 className='mt-2 mx-2'>Telefono: 433 3059 </h6>
                  </div>


                  <a href="https://api.whatsapp.com/send?phone=+542646184400&text=Turno web" target="_blank" rel="noopener noreferrer" >

                    <div id="whatsapp" className='d-flex align-items-center'>
                      <img src="./imagenes/whatsapp.png" title="Escríbenos por Whastapp" width={'20px'} alt="Escríbenos por Whastapp" />

                      <h6 className='mt-2 mx-2'>Whatsapp: 2646184400 </h6>
                    </div>

                  </a>

                  <p className="mx-4"> (solo mensajes)</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-8  col-md-4">

            <div className="row" >
              <div className="col text-h3 ">
                <div ><h5><b>ECOGRAFÍAS</b></h5></div>
                <div className="text-p2">

                  <a href="https://api.whatsapp.com/send?phone=+542646741359&text=Turno web" target="_blank" rel="noopener noreferrer" >

                    <div id="whatsapp" className='d-flex align-items-center'>

                      <img src="./imagenes/whatsapp.png" title="Escríbenos por Whastapp" width={'20px'} alt="Escríbenos por Whastapp" />
                      <h6 className='mt-2 mx-2'>Whatsapp: 2646741359 </h6>

                    </div>

                  </a>
                </div>
                <p className="mx-4"> (solo mensajes)</p>
              </div>
            </div>

          </div>
          <div className="col-8  col-md-4">

            <div className="row" >
              <div className="col text-h3 ">
                <div ><h5><b>LABORATORIO</b></h5></div>
                <div className="text-p2">

                  <a href="https://api.whatsapp.com/send?phone=+542645777559&text=Turno web" target="_blank" rel="noopener noreferrer" >

                    <div id="whatsapp" className='d-flex align-items-center'>

                      <img src="./imagenes/whatsapp.png" title="Escríbenos por Whastapp" width={'20px'} alt="Escríbenos por Whastapp" />
                      <h6 className='mt-2 mx-2'>Whatsapp: 2645777559 </h6>

                    </div>

                  </a>

                </div>
                <p className="mx-4"> (solo mensajes)</p>

              </div>
            </div>

          </div>
        </div>
      </div>


      <div className=" mt-4 mb-4 p-4 " >
        <h4 className="text-center bg-info mb-4"><BsAlarm /> <b>HORARIO DE ATENCIÓN</b></h4>
        <div className="row justify-content-center items-center mx-auto rounded-pill">


          <div className="col-8  col-md-3">

            <div className="row" >
              <div className="col text-h3 ">
                <div className="mb-2"><b>CONSULTORIOS</b></div>
                <div className="text-p2">

                  <p>de LUNES a VIERNES</p>
                  <p>de 8:00 hs. a 12:00 hs.</p>
                  <p>y de 16:30 hs. a 20:00 hs.</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-8  col-md-3">

            <div className="row" >
              <div className="col text-h3 ">
                <div className="mb-2"><b>LABORATORIO</b></div>
                <div className="text-p2">

                  <p>de LUNES a VIERNES</p>
                  <p>de 7:30 hs. a 12:00 hs.</p>
                  <p>y de 16:30 hs. a 20:00 hs.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div>
        <h3 className="text-center mt-4 p-4 bg-primary text-white">COMO LLEGAR</h3>
        <iframe src="https://maps.google.com/?ll=-31.5303433,-68.5939558&z=18&t=m&output=embed" width="100%" height="450" title="map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>



      <Footer></Footer>
    </>

  )

}

