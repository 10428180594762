import React from "react";
import { useAuth } from "../context/authContext";

import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export function AdminView() {
  const { user, logout, loading } = useAuth();

  const [key, setKey] = useState("home");

  const handleLogut = async () => {
    try {
      await logout();
    } catch (error) {
      console.log(error);
    }
  };

  if (loading)
    return (
      <div class="d-flex justify-content-center mt-4">
        <div class="spinner-border text-danger" role="status">
          <span class="visually-hidden">c...</span>
        </div>
      </div>
    );

  return (
    <div
      className="mx-auto p-2 "
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        width: "100vw",
        height: "100vh",
        paddingTop: "50px",
        marginBotton: "10px",
        backgroundColor: "#0093E9",
        backgroundImage: "linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)",
      }}
    >
      <div className="container bg-light rounded h-100 p-4">
        <h4 className="text-lg font-bold mb-4 ">
          Bienvenido {user.displayName || user.email}
        </h4>
        <button onClick={handleLogut} className="btn btn-dark text-white mb-4">
          Salir
        </button>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="home" title="Usuarios">
            Tab content for Home
          </Tab>
          <Tab eventKey="profile" title="Clientes">
            Tab content for Profile
          </Tab>
          <Tab eventKey="contact" title="Estudios" >
            Tab content for Contact
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
