import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './Footer.css'

import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";

const Footer = () => {
  return (

    <div >
      <footer id='pie' className='bg-success'>
        <Nav className='row' >

          <Link to='/' className='col-12 col-md-2 text-white d-flex align-items-center justify-content-center'>

            <img
              src="logo.png"
              width="50"
              height="50"
              className="d-inline-block align-center mx-2 "
              alt="logo"

            /> <h6 className='m-2'>CONSULTORIOS SAN MARTIN</h6>
          </Link>
          <div className="col-md-4 mx-2">
            <div className="row ">
              <ul className='col-md-5 list-unstyled m-2 '>
                <li>Clínica Médica</li>
                <li>Pediatría</li>
                <li>Odontología</li>
                <li>Diabetología</li>
                <li>Ecografía</li>

              </ul>
              <ul className='col-md-5 list-unstyled m-2 '>

                <li>Cardiología</li>
                <li>Nutrición</li>
                <li>Psicología</li>
                <li>Laboratorio de Analisis Clinicos</li>
              </ul>
            </div>
          </div>

          <ul className='col-md-3 mx-3 list-unstyled m-2'>
            <li>Central de Turnos</li>
            <li>Telefono: 264 433-3059</li>
            <li>laboratoriosanmartin72@gmail.com</li>
            <li>Whatsapp: 2646184400</li>

          </ul>
          <div className="col-md-2" >
            <div className='mb-2 mx-3'>
              <h6 className='text-white'>Seguinos</h6>
              <a className='text-decoration-none' href="https://www.facebook.com/groups/centromedicosanmartin" target="_blank" rel="noopener noreferrer" >
                <div id="face" className='d-flex align-items-center text-white'>

                  <FaFacebookSquare size={24} />
                  <h6 className='mt-2 mx-2 '>Facebook </h6>
                </div>
              </a>

              <a className='text-decoration-none' href="https://instagram.com/centro.sanmartin" target="_blank" rel="noopener noreferrer" >
                <div id="face" className='d-flex align-items-center text-white'>

                  <FaInstagramSquare size={24} />
                  <h6 className='mt-2 mx-2 '>Instagram </h6>
                </div>
              </a>


            </div>
            
          </div>


        </Nav>
      </footer>


    </div>
  )
}

export default Footer