import React from 'react'
import Footer from './Footer'



export const Nosotros = () => {
  return (
    <>
    <img src="imagenes/IMG-20230923.jpg" alt="foto" className='img-fluid' />
    <div className='m-4 h-100' style={{ minHeight: '80vh' }}>
      
      <h5>  Quiénes Somos</h5>    
<h1><i class="bi bi-facebook"></i></h1>
      <p>Somos un equipo de profesionales médicos dedicados a proporcionar atención de la más alta
        calidad a nuestros pacientes.
       
      </p>
      
      <p>Con años de experiencia en el campo de la medicina, estamos comprometidos con su salud y su satisfacción. </p>


      <h5>Nuestra Misión</h5>

      <p>Nuestra misión es simple pero poderosa: mejorar la salud y la calidad de vida de nuestros pacientes. Trabajamos incansablemente para ofrecer diagnósticos precisos y tratamientos efectivos en un entorno cálido y acogedor.</p>


      <h5>Nuestro Equipo</h5>

      <p>Contamos con un equipo de médicos altamente capacitados y especializados en diversas áreas de la medicina. Cada uno de nuestros profesionales está comprometido con la excelencia y se esfuerza por mantenerse actualizado en las últimas tendencias y avances médicos.</p>

      <h5>Nuestro Compromiso con Usted</h5>

      <p>En el consultorio, su salud es nuestra prioridad número uno. Nos esforzamos por proporcionar atención personalizada y comprensiva a cada paciente. Nos preocupamos por usted, y trabajaremos juntos para abordar sus necesidades de salud de manera efectiva.</p>

      <h5>Contáctenos</h5>

      <p>Estamos aquí para ayudarlo en cada paso de su viaje hacia una vida más saludable. No dude en ponerse en contacto con nosotros para programar una cita o si tiene alguna pregunta. Estamos deseando servirle.</p>


      <p>Gracias por confiar en Consultorios San Martin como su fuente de atención médica confiable.</p>
      
      
    
    </div>
<Footer/>

</>
    
  )
}
