import React from "react";
import { useAuth } from "../context/authContext";

export function UserView() {

  const { user, logout, loading } = useAuth();

   const handleLogut = async () => {

      try {

         await logout();

      } catch (error) {
         console.log(error);
      }
   }

   if (loading) return <h1>Cargando</h1>


  return (
    <>
    
    <div>UserView</div>

    <div className="container mx-auto p-2 ">
            <div className="bg-slate-500 rounded shadow-lg p-4">
               <div className="grid place-content-center text-lg">
                  <h1 className="text-lg font-bold mb-4">Bienvenido  {user.nombre || user.email}</h1>
                  <button type="button" onClick={handleLogut}
                     className="text-white hover:text-white border 
                     border-gray-800 hover:bg-gray-900 focus:ring-4 
                     focus:outline-none focus:ring-gray-300 font-bold 
                     rounded-lg text-lg px-5 py-2.5 text-center mr-2 mb-2 
                     dark:border-gray-600 dark:text-gray-400 dark:hover:text-white 
                     dark:hover:bg-gray-600 dark:focus:ring-gray-800">
                     Salir
                  </button>
                  
               </div>
            </div>
      </div>
    </>
    


  )
}
