import React from 'react'
import Footer from './Footer'

import { BsFillTelephoneFill } from "react-icons/bs";

export const Psicologia = () => {
  return (
    <>
      <div className='container h-100 mb-4' style={{ minHeight: '67vh' }}>
        <div className="row">

          <div className="col-md-4 text-center">

            <img src="imagenes/psicologia1.jpg" alt="lab" width={300} />


          </div>
          <div className="col-md-8">
            <h1 className='m-4'>Psicologia</h1>
            <h4 className='m-4 text-primary'>Atendido por la Lic. Isabel Galleguillo</h4>

            <div className=' mb-4'>
              <h6>Atencion en psicologia</h6>
              <p>La psicología o sicología es una ciencia social y una disciplina académica enfocadas en el análisis y la comprensión de la conducta humana y de los procesos mentales experimentados por individuos y por grupos sociales durante momentos y situaciones determinadas.</p>

              <h5>Estudios</h5>
              <ul>
                <li>Evaluacion de atención, orientación, memoria, inteligencia, lenguaje y juicio 
                  como indicadores del estado de tu salud mental</li>
                
              </ul>
            </div>    

          </div>

        </div>

        <div className="row">
        <div className='col-md-4'></div>
          <div className="col-md-4">
            <div className="card mb-4">

              <h5 className="mt-4 mx-2">HORARIO DE ATENCIÓN</h5>
              <hr></hr>
              <p className=" mx-2">DIA MARTES</p>
              <p className="mx-2">DE MAÑANA, CON TURNO</p>
              
            </div>


          </div>
          <div className="col d-flex">
            <div className="card mb-4">
            <div className="col text-h3 p-4 ">
                  <div ><h5><b>TURNOS Y CONSULTAS</b></h5></div>
                  <div className="text-p2">

                    <div className='d-flex align-items-center'>

                      <BsFillTelephoneFill /> <h6 className='mt-2 mx-2'>Telefono: 433 3059 </h6>
                    </div>


                    <a href="https://api.whatsapp.com/send?phone=+542646184400&text=Turno web clinica medica" target="_blank" rel="noopener noreferrer" >

                      <div id="whatsapp" className='d-flex align-items-center'>
                        <img src="./imagenes/whatsapp.png" title="Escríbenos por Whastapp" width={'20px'} alt="Escríbenos por Whastapp" />
                        <h6 className='mt-2 mx-2'>Whatsapp: 2646184400 </h6>
                      </div>
                    </a>


                  </div>
                  <p className="mx-4"> (solo mensajes)</p>

                </div>
            </div>
          </div>

        </div>
      </div>
      <Footer/>

      

    </>

  )
}


