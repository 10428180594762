import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './components/Home';
import { Login } from './components/Login';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Register } from './components/Register';
import { AdminView } from './components/AdminView';
import { UserView } from './components/UserView';
import { AuthProvider } from './context/authContext';
import { Nosotros } from './components/Nosotros';
import { Staff } from './components/Staff';
import { Servicios } from './components/Servicios';
import { Contacto } from './components/Contacto';

import { Laboratorio } from './components/Laboratorio';
import {Cardiologia} from './components/Cardiologia'
import {Clinica} from './components/Clinica'
import {Diabetes} from './components/Diabetes'
import {Ecografia} from './components/Ecografia'
import {Nutricion} from './components/Nutricion'
import {Odontologia} from './components/Odontologia'
import {Pediatra} from './components/Pediatra'
import {Psicologia} from './components/Psicologia'

import 'bootstrap/dist/css/bootstrap.min.css';


import './index.css';
import BasicExample from './components/BasicExample';

function App() {

  return (
    <div>
      <AuthProvider>
        <BasicExample />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={
            <ProtectedRoute>
              <AdminView />
            </ProtectedRoute>
          } />
          <Route path="/user" element={
            <ProtectedRoute>
              <UserView />
            </ProtectedRoute>
          } />

          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/contacto" element={<Contacto />} />

          <Route path="/clinica" element={<Clinica />} />
          <Route path="/pediatra" element={<Pediatra />} />
          <Route path="/odontologia" element={<Odontologia />} />
          <Route path="/diabetes" element={<Diabetes />} />
          <Route path="/ecografia" element={<Ecografia />} />
          <Route path="/cardiologia" element={<Cardiologia />} />
          <Route path="/nutricion" element={<Nutricion />} />
          <Route path="/psicologia" element={<Psicologia />} />
          <Route path="/laboratorio" element={<Laboratorio />} />

          <Route path="/login" element={<Login />} />
          
          <Route path="/register" element={

            <ProtectedRoute>
              <Register />
            </ProtectedRoute>
          } />

          <Route path="*" element={<Home />} />

        </Routes>
                
      </AuthProvider>


    </div>

  )
}

export default App
