import React from 'react'

import Footer from './Footer'

import { BsFillTelephoneFill } from "react-icons/bs";

export const Odontologia = () => {
  return (
    <>
      <div className='container mb-4 h-67' style={{ minHeight: '67vh' }}>
        <div className="row">

          <div className="col-md-4 text-center">

            <img src="imagenes/odontologia.jpg" alt="lab" width={300} />


          </div>
          <div className="col-md-8">
            <h1 className='m-4'>Odontología</h1>
            <h4 className='m-4 text-primary'>Atendido por la Dra. Lucia Ibañez</h4>

            <div className=' mb-4'>
              <h6>Atención odontológica</h6>
              <p>
                El odontólogo/a se encarga de la prevención, el diagnóstico y el tratamiento
                de las enfermedades bucales como son: caries, enfermedades periodontales y maloclusiones,
                con una visión integradora

              </p>

              <h5>Odontología Integral</h5>

              <ul>
                <li>Prótesis removibles parciales y totales</li>
                <li>Prótesis fija </li>
                <li>Extracciones</li>
                <li>Limpieza dental</li>
                <li>Blanqueamiento</li>
                <li>Tratamiento de caries y obturaciones estéticas </li>
                <li>Endodoncia</li>

              </ul>
            </div>

          </div>


        </div>

        <div className="row">
        <div className='col-md-4'></div>
          <div className="col-md-4">
            <div className="card mb-4">

              <h5 className="mt-4 mx-2">HORARIO DE ATENCIÓN</h5>
              <p className=" mx-2">LUNES, MIERCOLES Y VIERNES</p>
              <p className="mx-2">de tarde, CON TURNO</p>

              <p className="mx-2">MARTES Y JUEVES</p>
              <p className="mx-2">de mañana, CON TURNO</p>

            </div>


          </div>
          <div className="col d-flex">
            <div className="card mb-4">
              <h5 className='mt-4 mx-2'>TURNOS Y CONSULTAS</h5>
              <div className='d-flex align-items-center'>

                <BsFillTelephoneFill /> <h6 className='mt-2 mx-2'>Telefono: 433 3059 </h6>
              </div>
              <div id="whatsapp" className='d-flex align-items-center'>

                <a href="https://api.whatsapp.com/send?phone=+542646184400&text=Turno web odontologia" target="_blank" rel="noopener noreferrer" >

                  <div id="whatsapp" className='d-flex align-items-center'>
                    <img src="./imagenes/whatsapp.png" title="Escríbenos por Whastapp" width={'20px'} alt="Escríbenos por Whastapp" />
                    <h6 className='mt-2 mx-2'>Whatsapp: 2646184400 </h6>
                  </div>
                </a>
                <h6 className='mt-2 mx-2'>(solo mensajes)</h6>
              </div>
            </div>
          </div>

        </div>
      </div>



      <Footer />


    </>

  )
}


