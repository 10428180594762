import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup,
    sendPasswordResetEmail,
} from 'firebase/auth';

import {doc, setDoc } from 'firebase/firestore';

import { auth, db } from '../firebase';

export const authContext = createContext();

export const useAuth = () => {
    const context = useContext(authContext);
    if (!context) throw new Error('Not auth provider');
    return context;
}

export function AuthProvider({ children }) {

    const [user, setUser] = useState(null);

    const [loading, setLoading] = useState(true);

    const signup = async (name, dni, telefono, email, password, rol) =>{
       const infoUsuario = await createUserWithEmailAndPassword(
           auth, 
           email, 
           password
           ).then((auth) => {
               return auth;
           });
        console.log(auth);

        // guardar los datos y el rol del usuario

          await setDoc(doc(db, `usuarios/${infoUsuario.user.uid}`), {
            nombre: name,
            dni: dni,
            telefono: telefono,
            correo: email,
            rol: rol
          });
        
    }

    const login = (email, password) => signInWithEmailAndPassword(auth, email, password);

    const logout = () => signOut(auth);

    const loginWithGoogle = () => {
        const googleProvider = new GoogleAuthProvider();
        return signInWithPopup(auth, googleProvider);
    };

    const resetPassword = (email) => {
        sendPasswordResetEmail(auth, email)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, currentUser => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => unsubscribe();

    }, []);

    return (
        <authContext.Provider value={{ signup, login, logout, user, loading, loginWithGoogle, resetPassword }}>
            {children}

        </authContext.Provider>
    )
}