import React from 'react'
import Footer from './Footer'

import { BsFillTelephoneFill } from "react-icons/bs";

export const Nutricion = () => {
  return (
    <>
      <div className='container h-100 mb-4' style={{ minHeight: '67vh' }} >
        <div className="row">

          <div className="col-md-4 text-center">

            <img src="imagenes/nutricion.jpg" alt="lab" width={300} />


          </div>
          <div className="col-md-8">
            <h1 className='m-4'>Nutricion</h1>
            <h4 className='m-4 text-primary'>Atendido por la Lic. Carolina Quiroga</h4>

            <div className=' mb-4'>
              <h6>Atencion en nutricion</h6>
              <p>
              La nutrición es la ciencia que estudia los procesos fisiológicos y metabólicos que ocurren en el 
              organismo con la ingesta de alimentos.

              Muchas enfermedades comunes y sus síntomas frecuentemente pueden ser prevenidas o aliviadas con una 
              determinada alimentación; por esto, la ciencia de la nutrición intenta entender cuáles son los aspectos
              dietéticos específicos que influyen en la salud.

              El propósito de la ciencia de la nutrición es explicar la respuesta metabólica y fisiológica del cuerpo
              ante la dieta. Con los avances en biología molecular, bioquímica y genética, la ciencia de la nutrición 
              se enfoca en el estudio del metabolismo, investigando la relación entre la dieta y la salud desde el 
              punto de vista de los procesos bioquímicos. El cuerpo humano está hecho de compuestos químicos tales 
              como agua, aminoácidos (proteínas), ácidos grasos (lípidos), ácidos nucleicos (ADN/ARN) 
              e hidrosilicatos (por ejemplo azúcares y fibra).

              </p>
              <p>Una alimentación adecuada es la que cubre:</p>
              <ul>
                <li>Los requisitos de energía a través de la metabolización de nutrientes como los 
                  hidratos de carbono, proteínas y grasas, guardando las proporciones adecuadas entre sí​. 
                  Estos requisitos energéticos están relacionados con el gasto metabólico basal, 
                  el gasto por la actividad física y el gasto inducido por la dieta.</li>

                <li>Las necesidades de micronutrientes no energéticos como las vitaminas y minerales.</li>                
                <li>La correcta hidratación basada en el consumo de bebidas, en especial el agua.</li>
                <li>La ingesta suficiente de fibra dietética.</li>
                <li>Los requerimientos mínimos de inocuidad alimentaria, de tal manera que no implique riesgos 
                  para la salud y esté libre de patógenos, toxinas y contaminantes.</li>
              </ul>

              <h5 className='mt-5'>Estudios</h5>
              <ul>
                <li>Evaluacion de la alimentacion</li>
                <li>Diagnostico nutricional </li>
                <li>Recomendacion dietetica</li>
              </ul>
            </div>

          </div>
          
        </div>

        <div className="row">
        <div className='col-md-4'></div>
          <div className="col-md-4">
            <div className="card mb-4">

              <h5 className="mt-4 mx-2">HORARIO DE ATENCIÓN</h5>
              
              <p className=" mx-2">DIA VIERNES</p>
              <p className="mx-2">DE TARDE, CON TURNO</p>
              
            </div>


          </div>
          <div className="col d-flex">
            <div className="card mb-4">
            <div class="col text-h3 p-4 ">
                  <div ><h5><b>TURNOS Y CONSULTAS</b></h5></div>
                  <div class="text-p2">

                    <div className='d-flex align-items-center'>

                      <BsFillTelephoneFill /> <h6 className='mt-2 mx-2'>Telefono: 433 3059 </h6>
                    </div>


                    <a href="https://api.whatsapp.com/send?phone=+542646184400&text=Turno web clinica medica" target="_blank" rel="noopener noreferrer" >

                      <div id="whatsapp" className='d-flex align-items-center'>
                        <img src="./imagenes/whatsapp.png" title="Escríbenos por Whastapp" width={'20px'} alt="Escríbenos por Whastapp" />
                        <h6 className='mt-2 mx-2'>Whatsapp: 2646184400 </h6>
                      </div>
                    </a>


                  </div>
                  <p className="mx-4"> (solo mensajes)</p>

                </div>
            </div>
          </div>

        </div>
      </div>
      <Footer/>




    </>

  )
}


