import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

//import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";


function BasicExample() {
  return (
    <>
      <div className='bg-success' style={{ color: 'white', textAlign: 'center' }}>
        Av. Libertador Gral. San Martín OESTE 5325, Rivadavia, San Juan
      </div>

      <Navbar collapseOnSelect expand="md" variant='dark' style={{ padding: '10px', backgroundColor: '#555' }}>
        <Container fluid >
          <Navbar.Brand href='#' as={Link} to="/" className='d-flex justify-center align-items-center'>
            <img
              src="logo.png"
              width="50"
              height="50"
              className="d-inline-block align-center mx-2 "
              alt="logo"

            />

            <h6 >CONSULTORIOS SAN MARTIN</h6>  </Navbar.Brand>
        
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
            <Nav navbarScroll>
              <Nav.Link href='#' as={Link} to="/">Inicio</Nav.Link>
              <NavDropdown title="Nosotros " id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/nosotros" href='#'>Nuestra Institucion</NavDropdown.Item>


              </NavDropdown>
              <NavDropdown title="Especialidades" id="basic-nav-dropdown">
                <NavDropdown.Item href='#' as={Link} to="/clinica">Clínica Médica</NavDropdown.Item>
                <NavDropdown.Item href='#' as={Link} to="/pediatra">Pediatría</NavDropdown.Item>
                <NavDropdown.Item href='#' as={Link} to="/odontologia">Odontología</NavDropdown.Item>
                <NavDropdown.Item href='#' as={Link} to="/diabetes">Diabetología</NavDropdown.Item>
                <NavDropdown.Item href='#' as={Link} to="/ecografia">Ecografía</NavDropdown.Item>
                <NavDropdown.Item href='#' as={Link} to="/cardiologia">Cardiología</NavDropdown.Item>
                <NavDropdown.Item href='#' as={Link} to="/nutricion">Nutrición</NavDropdown.Item>
                <NavDropdown.Item href='#' as={Link} to="/psicologia">Psicología</NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item href='#' as={Link} to="/laboratorio">Laboratorio</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href='#' as={Link} to="/staff">Profesionales</Nav.Link>
              <Nav.Link href='#' as={Link} to="/contacto">Contacto</Nav.Link>
            {/*
              <Nav.Link href="https://www.facebook.com/groups/centromedicosanmartin" target="_blank" rel="noopener noreferrer"><FaFacebookSquare size={20} className='text-white' /></Nav.Link>
              <Nav.Link href="https://instagram.com/centro.sanmartin" target="_blank" rel="noopener noreferrer"><FaInstagramSquare size={20} className='text-white' /></Nav.Link>
  */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


    </>
  );
}

export default BasicExample;