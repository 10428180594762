import React from 'react'
import Footer from './Footer'

import { BsFillTelephoneFill } from "react-icons/bs";

export const Cardiologia = () => {
  return (
    <>
      <div className='container h-100 mb-4' style={{ minHeight: '67vh' }}>
        <div className="row">

          <div className="col-md-4 text-center">

            <img src="imagenes/cardiologo.jpg" alt="lab" width={300} />


          </div>
          <div className="col-md-8">
            <h1 className='m-4'>Cardiología</h1>
            <h4 className='m-4 text-primary'>Atendido por el Dr. Raul Trigo</h4>
            <div className=' mb-4'>
              <h6>Atención en cardiología</h6>
              <p>La cardiología es la rama de la medicina que se encarga del estudio, prevención, diagnóstico y tratamiento de las enfermedades del corazón y del sistema circulatorio.</p>

              <h5>Estudios</h5>
              <ul>
                <li>Cardiología clínica, es decir todo lo relacionado con la asistencia directa a pacientes y la toma de decisiones sobre como actuar en la situación individual de cada uno de ellos.</li>
                <li>Cardiología preventiva, es decir el estudio de las medidas que tienden a evitar la aparición de la enfermedad en el campo de la cardiología </li>
                <li> Electrocardiografía</li>
                <li>Monitorización continua de 24 horas (test de Holter)</li>
                <li>Prueba de esfuerzo o ergometría</li>
                <li>Monitoreo continuo de la presión arterial (MAPA)</li>
                <li>Ecocardiografía</li>

              </ul>
            </div>     
          </div>


          

        </div>

        <div className="row">
        <div className='col-md-4'></div>
          <div className="col-md-4">
            <div className="card mb-4">

              <h5 className="mt-4 mx-2">HORARIO DE ATENCIÓN</h5>
              <p className=" mx-2">LUNES y JUEVES</p>
              <p className="mx-2">de 7:30 hs. a 12:00 hs.</p>
              <p className="mx-2">por orden de llegada</p>
              
            </div>


          </div>
          <div className="col d-flex">
            <div className="card mb-4">
              <h5 className='mt-4 mx-2'>TURNOS Y CONSULTAS</h5>
              <div className='d-flex align-items-center mx-2'>

                <BsFillTelephoneFill /> <h6 className='mt-2 mx-2'>Telefono: 433 3059 </h6>
              </div>
              <div id="whatsapp" className='d-flex align-items-center mx-2'>

              <a href="https://api.whatsapp.com/send?phone=+542646741359&text=Turno web" target="_blank" rel="noopener noreferrer" >
                      <div id="whatsapp" className='d-flex align-items-center'>
                        <img src="./imagenes/whatsapp.png" title="Escríbenos por Whastapp" width={'20px'} alt="Escríbenos por Whastapp" />
                        <h6 className='mt-2 mx-2'>Whatsapp: 2646184400 </h6>
                      </div>
                    </a>
                   
              </div>
               <p className="mx-4"> (solo mensajes)</p>
            </div>
          </div>

        </div>
      </div>
      <Footer/>

      

    </>

  )
}


