import React from 'react'

import Footer from './Footer'


export const Ecografia = () => {
  return (
    <>
      <div className='container h-100 mb-4' style={{ minHeight: '67vh' }}>
        <div className="row">

          <div className="col-md-4 text-center">

            <img src="imagenes/ecografia.jpg" alt="lab" width={300} />


          </div>

          <div className="col-md-8">
            <h1 className='m-4'>Ecografia</h1>

            
            <div className=' mb-4'>
              <h4>Diagnosticos por imagenes</h4>
              <p>Una ecografía es una prueba de diagnóstico por imagen que utiliza ondas sonoras para crear imágenes de órganos, tejidos y estructuras del interior del cuerpo. Permite a su profesional de la salud observar al interior del cuerpo sin una cirugía. La ecografía también se llama sonografía o ultrasonido.</p>


              <h4 className='text-primary mb-4'>Atendido por el Dr. Sergio de la Vega</h4>
              <h5 className='mt-4'>Ecografía General:</h5>
              <ul>
                <li>Abdominal</li>
                <li>Renal</li>
                <li>Prostática</li>
                <li>Vesical</li>
                <li>Tiroides</li>
                <li>Partes blandas</li>
                <li>Testicular</li>
                <li>Ginecológicas</li>
                <li>Transvaginal</li>
                <li>Mamaria</li>
                <li>Obstétricas</li>
                <li>Scan fetal detallado</li>
                <li>Doppler obstétrico</li>
                <li>Doppler renal, hepático, testicular y tiroides</li>
               
              </ul>
              <br />
              <h4 className='text-primary mb-4'>Atendido por el Dr. Alejandro Peñaloza</h4>
              <h5 className='mt-4'>Ecografía:</h5>
              <ul>
                <li>Ecodoppler cardíaco</li>
                <li>Doppler de vasos de cuello</li>
                <li>Doppler venoso y arterial de miembros superiores e inferiores</li>
                
               
              </ul>
            </div>


          </div>

        </div>


        <div className="row">
          <div className='col-md-4'></div>
          <div className="col-md-4">
            <div className="card mb-4">

              <h5 className="mt-4 mx-2 text-center">HORARIO DE ATENCIÓN </h5> 
              <hr></hr>
              <h6 className="mx-2">Dr PEÑALOZA :</h6>
              <p className="mx-2">DIA MIERCOLES DE MAÑANA CON TURNO</p>

              <hr></hr>
              
              <h6 className=" mx-2"> Dr De La Vega : </h6>
              <p className=" mx-2"> DIAS LUNES Y JUEVEZ DE MAÑANA </p>
              <p className=" mx-2"> DIA MIERCOLES DE TARDE CON TURNO </p>
              
            </div>


          </div>
          <div className="col d-flex">
            <div className="card p-4">
              
              <div className="col text-h3 ">
                  <div ><h5><b>TURNOS Y CONSULTAS</b></h5></div>
                  <div className="text-p2">

                    <a href="https://api.whatsapp.com/send?phone=+542646741359&text=Turno web ecografia" target="_blank" rel="noopener noreferrer" >
                      <div id="whatsapp" className='d-flex align-items-center'>
                        <img src="./imagenes/whatsapp.png" title="Escríbenos por Whastapp" width={'20px'} alt="Escríbenos por Whastapp" />
                        <h6 className='mt-2 mx-2'>Whatsapp: 2646741359 </h6>
                      </div>
                    </a>


                  </div>
                  <p className="mx-4"> (solo mensajes)</p>

                </div>
              
            </div>
          </div>

        </div>
      </div>
      <Footer/>


    </>

  )
}


