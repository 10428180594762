import React from 'react'
import Footer from './Footer'
import { BsFillTelephoneFill, BsEnvelope } from 'react-icons/bs'


export const Laboratorio = () => {
  return (
    <>
      <div className='mx-2 mb-4 h-100' style={{ minHeight: '67vh' }}>

        <div className="row">

          <div className="col-md-4 text-center">
            <img src="imagenes/laboratorio.jpg" alt="lab" width={300} />
          </div>
          <div className="col-md-8">
            <h2 className='m-4 '>Laboratorio de análisis clínicos</h2>

            <div className='mb-4'>

              <p>
                Nuestra función es contribuir al estudio, prevención, diagnóstico y tratamiento de patologías, analizando diferentes muestras biológicas, proporcionando resultados precisos que contribuyen a la atención medica de calidad.

                <br /> Estamos equipados con instrumentos, reactivos y personal capacitado.
                <br />Realizamos una amplia variedad de exámenes que aportan datos esenciales para el diagnóstico y seguimiento de enfermedades.


              </p>

              <h5 className='bg-danger text-white p-2 mt-4 mb-4'><b>Contamos con atención ambulatoria y a domicilio.</b></h5>

            </div>

          </div>


        </div>
        <div className="row mx-2" >
          <div className="col-md-4"></div>


          <div className="col-md-8 p-2" style={{ border: 'solid', borderColor: '#0055aa', borderRadius: '10px' }}>
            <div className="row">
            <div className="col-md-4">
              <h6 className='mt-2 mb-2'>Staff de profesionales</h6>
              <ul>
                <li>Bioq. Maria Lorena Herrera</li>
                <li>Bioq. Sandra V. Gomez</li>
                <li>Bioq. Adriana Di Salvo</li>
                <li>Bioq. Denise Mateos</li>
              </ul>
              
            </div>
            <div className="col-md-5">
              <h6 className='mt-2 mb-2'>Técnicos en Laboratorio de Análisis Clínicos:</h6>
              <ul>
                <li>Alan Jesús Gonzalez Rodriguez</li>
              </ul>

            </div>
            <div className="col-md-3">
              <h6 className='mt-2 mb-2'>Secretaría:</h6>
              <ul>
                <li>A. Carolina Rico</li>
                
              </ul>

            </div>
            </div>

          </div>
        </div>

        <div className="row">
          <div className="col-md-4">

          </div>
          <div className="col-md-8">
            <h5 className='mt-4'>Listado de análisis clínicos</h5>
            <ul>
              <li>Controles Química Clínica</li>
              <li>Hematológicos/ Hemostasia</li>
              <li>Controles de Embarazo Normal y de Alto Riesgo</li>
              <li>Serología </li>
              <li>Grupo y Factor</li>
              <li>Endocrinología</li>
              <li>Marcadores Tumorales</li>
              <li>Marcadores Enfermedad Celiaca</li>
              <li>Virología</li>
              <li>Inmunología</li>
              <li>Autoinmunidad/ Alergias</li>
              <li>Bacteriología</li>
              <li>Micología</li>
              <li>Parasitología</li>
              <li>Hisopados COVID</li>
              <li>Determinaciones de Alta Complejidad con gestión de derivaciones a Laboratorios certificados con gran aval en el proceso de muestras de todo el país</li>

            </ul>
          </div>
        </div>

        <div className="row">
        <div className='col-md-4'></div>
          <div className="col-md-4">
            <div className="card mb-4">

              <h5 className="mt-4 mx-2">HORARIO DE ATENCIÓN</h5>
              <hr></hr>
              <p className=" mx-2">de LUNES a VIERNES, POR ORDEN DE LLEGADA</p>
              <p className="mx-2">de 7:30 hs. a 10:00 hs. PARA EXTRACCION</p>
              <p className="mx-2">CONSULTAS Y ATENCIÓN</p>
              <p className="mx-2">de 7:30 hs. a 12:00 hs.</p>
              <p className="mx-2">y de 16:30 hs. a 20:00 hs.</p>

            </div>


          </div>
          <div className="col d-flex">
            <div className="card mb-4">
              <h5 className='mt-4 mx-2'>TURNOS, SOLICITUD DE EXTRACCION A DOMICILIO Y CONSULTAS </h5>

              <div class="text-p2 p-2">

                <div className='d-flex align-items-center'>

                  <BsFillTelephoneFill /> <h6 className='mt-2 mx-2'>Telefono: 433 3059 </h6>
                </div>


                <a href="https://api.whatsapp.com/send?phone=+542646184400&text=Turno web laboratorio" target="_blank" rel="noopener noreferrer" >

                  <div id="whatsapp" className='d-flex align-items-center'>
                    <img src="./imagenes/whatsapp.png" title="Escríbenos por Whastapp" width={'20px'} alt="Escríbenos por Whastapp" />
                    <h6 className='mt-2 mx-2'>Whatsapp: 2645777559  </h6>
                  </div>
                </a>



                <p className="mx-4"> (solo mensajes)</p>

                <div className='d-flex align-items-center'>
                  <BsEnvelope /> <h6 className='mt-2 mx-2'>Correo : laboratoriosanmartin72@gmail.com</h6>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />



    </>

  )
}


