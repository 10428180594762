import React from 'react'
import Row from 'react-bootstrap/Row';

import imagen1 from '../img/medico1.jpg'
import imagen2 from '../img/pediatra1.jpg'
import imagen3 from '../img/odontologia1.jpg'
import imagen4 from '../img/diabetes1.jpg'
import imagen5 from '../img/ecografia1.jpg'
import imagen6 from '../img/cardiologo1.jpg'
import imagen7 from '../img/nutricion1.jpg'
import imagen8 from '../img/psicologia1.jpg'
import imagen9 from '../img/laboratorio1.jpg'

import './cards.css'
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const cards = [
  {
    id: 1,
    title: 'Clínica Médica',
    texto: 'Consultorio medico',
    image: imagen1,
    link: '/clinica'
  },
  {
    id: 2,
    title: 'Pediatría',
    texto: 'Consultorio pediatrico',
    image: imagen2,
    link: '/pediatra'
  },
  {
    id: 3,
    title: 'Odontología',
    texto: 'Consultorio odontologico',
    image: imagen3,
    link: '/odontologia'
  },
  {
    id: 4,
    title: 'Diabetología',
    texto: 'Especialista en diabetes',
    image: imagen4,
    link: '/diabetes'
  },
  {
    id: 5,
    title: 'Ecografía',
    texto: 'Estudios por imagenes',
    image: imagen5,
    link: '/ecografia'
  },
  {
    id: 6,
    title: 'Cardiología',
    texto: 'Consultorio cardiologico',
    image: imagen6,
    link: '/cardiologia'
  },
  {
    id: 7,
    title: 'Nutrición',
    texto: 'Especialista en nutricion',
    image: imagen7,
    link: '/nutricion'
  },
  {
    id: 8,
    title: 'Psicología',
    texto: 'Consultorio psicologico',
    image: imagen8,
    link: '/psicologia'
  },

  {
    id: 9,
    title: 'Laboratorio',
    texto: 'Analisis clinicos',
    image: imagen9,
    link: '/laboratorio'
  }
]

const Cards = () => {
  return (
    <>

      <Row xs={2} md={3} >
        {
          cards.map(card => (


            <Container  key={card.id}>
              <Link to={card.link} reloadDocument style={{ textDecoration: 'none' }}>
                <div className='cards text-center m-4' >

                  <img
                    src={card.image}
                    alt=""
                    className='img img-fluid'
                    style={{
                      maxWidth: 'auto',
                      height: '100%',
                      overflow: 'hidden'
                      
                    }}
                  />

                  <h3 className='text-black'>{card.title}</h3>

                </div>
              </Link>
            </Container>
            

          ))
        }




      </Row >

    </>
  )
}

export default Cards