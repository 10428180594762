// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBCNbyZWDUSm63g5lZ0G7pMbck4XisQkec",
  authDomain: "eactapp.firebaseapp.com",
  projectId: "eactapp",
  storageBucket: "eactapp.appspot.com",
  messagingSenderId: "391907218054",
  appId: "1:391907218054:web:580982ca0b69ac6c9f3707"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Inicialize Auth
export const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

//"hosting": {
//  "site": "consultorio-sanmartin",

//  "public": "public",

//}

//firebase deploy --only hosting:consultorio-sanmartin