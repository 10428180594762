import React from "react";
import { useAuth } from "../context/authContext";
import { Navigate } from "react-router-dom";

export function ProtectedRoute({ children }) {

    const { user, loading } = useAuth();

    if (loading) return (
        <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">c...</span>
            </div>
        </div>
    )

    if (!user) return <Navigate to="/login" />

    return <>{children}</>

}