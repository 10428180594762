import React from 'react'
import Footer from './Footer'

import { BsFillTelephoneFill } from "react-icons/bs";

export const Clinica = () => {
  return (
    <>
      <div className='container mb-4 h-100' style={{ minHeight: '67vh' }}>
        <div className="row">

          <div className="col-md-4 text-center">

            <img src="imagenes/medico.jpg" alt="lab" width={300} />


          </div>
          <div className="col-md-8 ml-4">
            <h1 >Clínica Médica</h1>

            <h4 className='text-primary mb-4'>Atendido por el Dr. Ricardo Sanz</h4>
            <div className=' mb-4'>
              <h6>Atencion en clinica medica</h6>
              <p>La clínica médica aborda una amplia variedad de enfermedades y condiciones médicas, brindando atención primaria y especializada a los pacientes adultos</p>


              <ul>
                <li>Consulta medica gral</li>
                
              </ul>
            </div>


          </div>
        </div>

        <div className="row">
        <div className='col-md-4'></div>
          <div className="col-md-4">
            <div className="card mb-4">

              <h5 className="mt-4 mx-2">HORARIO DE ATENCIÓN</h5>
              <p className=" mx-2">LUNES, MIERCOLES Y VIERNES</p>
              <p className="mx-2">de Tarde, con TURNO</p>
              

            </div>


          </div>
          <div className="col d-flex">
            <div className="card mb-4">
              
              <div class="col text-h3 p-4 ">
                  <div ><h5><b>TURNOS Y CONSULTAS</b></h5></div>
                  <div class="text-p2">

                    <div className='d-flex align-items-center'>

                      <BsFillTelephoneFill /> <h6 className='mt-2 mx-2'>Telefono: 433 3059 </h6>
                    </div>


                    <a href="https://api.whatsapp.com/send?phone=+542646184400&text=Turno web clinica medica" target="_blank" rel="noopener noreferrer" >

                      <div id="whatsapp" className='d-flex align-items-center'>
                        <img src="./imagenes/whatsapp.png" title="Escríbenos por Whastapp" width={'20px'} alt="Escríbenos por Whastapp" />
                        <h6 className='mt-2 mx-2'>Whatsapp: 2646184400 </h6>
                      </div>
                    </a>


                  </div>
                  <p className="mx-4"> (solo mensajes)</p>

                </div>
              
            </div>
          </div>

        </div>
      </div>
      <Footer/>

    </>

  )
}


