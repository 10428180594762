import React from 'react';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

import imagen1 from '../img/medico1.jpg'
import imagen2 from '../img/pediatra1.jpg'
import imagen3 from '../img/odontologia1.jpg'
import imagen4 from '../img/diabetes1.jpg'
import imagen5 from '../img/ecografia1.jpg'
import imagen6 from '../img/cardiologo1.jpg'
import imagen7 from '../img/nutricion1.jpg'
import imagen8 from '../img/psicologia1.jpg'
import imagen9 from '../img/laboratorio1.jpg'

const portadas = [
  {
    id: 1,
    title: 'Clínica Médica',
    texto: 'Consultorio medico',
    image: imagen1,
    link: '/clinica'
  },
  {
    id: 2,
    title: 'Pediatría',
    texto: 'Consultorio pediatrico',
    image: imagen2,
    link: '/pediatra'
  },
  {
    id: 3,
    title: 'Odontología',
    texto: 'Consultorio odontologico',
    image: imagen3,
    link: '/odontologia'
  },
  {
    id: 4,
    title: 'Diabetología',
    texto: 'Especialista en diabetes',
    image: imagen4,
    link: '/diabetes'
  },
  {
    id: 5,
    title: 'Ecografía',
    texto: 'Estudios por imagenes',
    image: imagen5,
    link: '/ecografia'
  },
  {
    id: 6,
    title: 'Cardiología',
    texto: 'Consultorio cardiologico',
    image: imagen6,
    link: '/cardiologia'
  },
  {
    id: 7,
    title: 'Nutrición',
    texto: 'Especialista en nutricion',
    image: imagen7,
    link: '/nutricion'
  },
  {
    id: 8,
    title: 'Psicología',
    texto: 'Consultorio psicologico',
    image: imagen8,
    link: '/psicologia'
  },

  {
    id: 9,
    title: 'Laboratorio',
    texto: 'Analisis clinicos',
    image: imagen9,
    link: '/laboratorio'
  }
]

function Carrusel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (


    <Row style={{ textAlign: 'center' }}>

      <Carousel activeIndex={index} onSelect={handleSelect} variant='dark' >
        {
          portadas.map(k => (

            <Carousel.Item key={k.id}>
              <img

                className="img-fluid"
                src={k.image}
                alt="m"
                
              />

              <div 
                style={{
                  position: 'absolute',
                  top: '80%', // Centra verticalmente
                  left: '50%', // Centra horizontalmente
                  transform: 'translate(-50%, -50%)', // Centra completamente
                  color: 'white', // Cambia el color del texto
                  backgroundColor: 'rgba(0, 0, 0, 0.4)', // Agrega un fondo semitransparente
                  padding: '0 20px', // Espaciado alrededor del texto
                  borderRadius: '20px'
                }}>
                
                  <h1 >{k.title}</h1>
                

                </div>

            </Carousel.Item>


          ))
        }

      </Carousel>
    </Row>


  );
}

export default Carrusel;