import React from "react";
import { useState } from "react";
import { useAuth } from "../context/authContext";
import { Link, useNavigate } from 'react-router-dom';
import { Alert } from './Alert';

export function Register() {

    const [user, setUser] = useState({
        name: '',
        dni: '',
        telefono: '',
        email: '',
        password: '',
        rol: ''
    });

    const { signup } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState();

    const handleChange = ({ target: { name, value } }) => {
        setUser({ ...user, [name]: value })
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setError('');
        try {
           await signup(user.name, user.dni, user.telefono, user.email, user.password, user.rol);

            navigate('/');

        } catch (error) {
            //console.log(error.code);
            if (error.code === "auth/invalid-email") {
                setError('Correo invaido');
            }
            if (error.code === "auth/email-already-in-use") {
                setError('El correo ya esta registrado');
            }
            if (error.code === "auth/weak-password") {
                setError('La contraseña debe tener mas de 6 digitos');
            }

            //setError(error.message);
        }

    }

    return (
        <div className="bg-dark">
            <div className="container"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'start',
                    width: '100vw',
                    height: '100vh',
                    paddingTop: '50px',
                }}
            >
                <div className="card" style={{
                    width: '400px',
                    boxShadow: 'rgb(0 0 0 / 20%) 1px 5px 10px',
                    paddingTop: '50px',
                    paddingBottom: '20px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    borderRadius: '8px',
                    backgroundColor: 'rgb(145,195,143)',
                    background: 'linear-gradient(180deg, rgba(145,195,143,1) 15%, rgba(156,203,213,1) 85%)'
                    
                }}>

            <h4 className="text-center mb-2">Registrarse en el sistema</h4>
            {error && <Alert message={error} />}


            <form onSubmit={handleSubmit} className="p-4">

            <div className="form-floating mb-3">
                    
                    <input
                        type="text"
                        name="name"
                        id="name"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Nombre" />
                        
                    <label htmlFor="name" >Nombre</label>

                </div>
                <div className="form-floating mb-3">
                    
                    <input
                        type="number"
                        name="dni"
                        id="dni"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="DNI" />
                        
                    <label htmlFor="dni" >DNI</label>

                </div>
                <div className="form-floating mb-3">
                    
                    <input
                        type="number"
                        name="telefono"
                        id="telefono"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Telefono" />
                        
                    <label htmlFor="telefono" >Telefono</label>

                </div>
                <div className="form-floating mb-3">
                    
                    <input
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Correo" />
                        
                    <label htmlFor="email" >Correo electronico</label>

                </div>
                <div className="form-floating mb-3">
                     <input
                        type="password"
                        name="password"
                        id="password"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="******" />
                    <label htmlFor="password" >Contraseña</label>
                   

                </div>
                <div className="mb-4">
                    <label htmlFor="rol" className="mb-2">Rol </label>
                    <select id="rol" name="rol" className="form-control" onChange={handleChange}
                    >
                        <option value="admin">Administrador </option>
                        <option value="user" >Usuario</option>
                        <option value="client" >Cliente </option>

                    </select>
                </div>
                <div className="d-flex justify-content-between">

                    <Link to='/login'>
                        <button
                            className="bg-primary text-sm text-white font-bold
                py-2 px-4 rounded ">
                            Volver
                        </button>
                    </Link>

                    <button
                        className="bg-primary text-sm text-white font-bold
                py-2 px-4 rounded ">
                        Registrar
                    </button>
                </div>



            </form>
        </div>
        </div>
        </div>
    )
}